import { useContext } from "react";
import "./stories.scss";
import { AuthContext } from "../../context/authContext";
import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { Link } from "react-router-dom";

const Stories = () => {
  const { currentUser } = useContext(AuthContext);

  const { isLoading, error, data } = useQuery(["stories"], () =>
    makeRequest.get("/stories").then((res) => {
      return res.data;
    })
  );

  //TODO Add story using react-query mutations and use upload function.
  console.log(data);
  return (
    <div className="stories">
      <div className="story">
        <img src={"/upload/" + currentUser.profilePic} alt="" />
        <span>{currentUser.name}</span>
        <button>+</button>
      </div>
      {error
        ? "Something went wrong"
        : isLoading
        ? "loading"
        : data.map((story) => (
            <div className="story" key={story.id}>
             
              <img src= {"/upload/" + story.img} alt="" />
              <Link
                to={`/profile/${story.userId}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <button><img src={"/upload/" + story.profilePic} alt="" /></button>
              </Link>
              
            
              
               <span>{story.name}</span>
            </div>
            
          ))}
    </div>
  );
};

export default Stories;
